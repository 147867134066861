<template>
  <div class="view pa24">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto">
      <el-row>
        <el-col :xl="10">
          <el-form-item label="活动时长" prop="pickerValue">
            <el-date-picker
              v-model="ruleForm.pickerValue"
              value-format="yyyy-MM-dd"
              align="right"
              type="daterange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
            />
          </el-form-item>
        </el-col>
        <el-col :xl="12">
          <el-form-item label="单账号最多开团数">
            <el-input-number v-model="ruleForm.createLimit" :min="1" />
          </el-form-item>
        </el-col>
        <el-col :xl="10">
          <el-form-item label="成团人数" prop="peopleNum">
            <el-input
              v-model="ruleForm.peopleNum"
              :min="2"
              @change="() => {
                ruleForm.peopleNum =  ruleForm.peopleNum < 2 ? 2 : ruleForm.peopleNum.replace(/\D/g,'')
              }"
              class="w120"
              placeholder="请输入人数"
              type="number"
            />
            拼团人数 > 1
          </el-form-item>
        </el-col>
        <el-col :xl="12">
          <el-form-item label="单账号最多购买数">
            <el-input-number v-model="ruleForm.maxPurchase" :min="1" />
          </el-form-item>
        </el-col>
        <el-col :xl="10">
          <el-form-item label="拼团有效期(小时)" prop="termOfValidity">
            <el-input
              v-model="ruleForm.termOfValidity"
              min="0"
              max="24"
              class="w120"
              placeholder="请输入人数"
              type="number"
            />
          </el-form-item>
        </el-col>
        <el-col :xl="12">
          <el-form-item label="单账号最多参团数">
            <el-input-number v-model="ruleForm.joinLimit" :min="1" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div style="color: #ff5c6c">
      注：请填写商品库存，一旦拼团人数和购买数量达到商品库存的值，该拼团则进入关闭状态，用户点击拼团或参团
      则提示“该商品已售罄”。
    </div>
    <div class="mt20" v-if="isDrafts">
      添加拼团商品
      <el-button @click="centerDialogVisible = true"  class="ml10" type="primary"
        >+添加商品</el-button
      >
    </div>
    <commonTable :tableData="tableData" :total="tableData.length" :paginationVisible='false'>
      <template v-slot:table>
        <el-table-column
          prop="goodsId"
          align="center"
          label="产品ID"
          show-overflow-tooltip
        />
        <el-table-column prop="goodsName" align="center" label="产品" />
        <el-table-column prop="goodsTypeName" align="center" label="分类" />
        <el-table-column prop="price" align="center" label="价格">
          <template slot-scope="scope">
            {{
              (scope.row[$route.query.id ? "minPrice" : "price"] / 100).toFixed(
                2
              )
            }}
          </template>
        </el-table-column>
        <el-table-column prop="stockNum" align="center" label="库存" />
        <el-table-column
          :prop="$route.query.id ? 'totalBuyNum' : 'dealNum'"
          align="center"
          label="总销量"
        />
        <el-table-column prop="createTime" align="center" label="发布时间">
          <template slot-scope="scope">
            {{ setGetDataTimeSec(scope.row.createTime) }}
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <p class="">选择商品规格</p>
    <commonTable :tableData="specList" :total="specList.length">
      <template v-slot:table>
        <el-table-column
          prop="goodsName"
          align="center"
          label="商品名称"
          show-overflow-tooltip
        />
        <el-table-column prop="typeName" align="center" label="商品类型" />
        <el-table-column align="center" prop="specAttribute" label="商品规格" />
        <el-table-column prop="stockNum" align="center" label="商品库存" />
        <el-table-column prop="price" align="center" label="价格">
          <template slot-scope="scope">
            {{ (scope.row.price / 100).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="500">
          <template slot-scope="scope">
            拼团金额
            <el-input
              class="d-inline-block w100"
              v-model="setSpec[scope.$index].assemblePrice"
              @change="() => {
                setSpec[scope.$index].assemblePrice =  setSpec[scope.$index].assemblePrice < 0.01 ? 0.01 : setSpec[scope.$index].assemblePrice
              }"
            />
            元 活动库存
            <el-input
              class="d-inline-block w100"
              v-model="setSpec[scope.$index].assembleNum"
              @change="() => {
                setSpec[scope.$index].assembleNum =  setSpec[scope.$index].assembleNum < 1 ? 1 : setSpec[scope.$index].assembleNum.replace(/\D/g,'')
              }"
            />
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
      title="选择商品"
      :visible.sync="centerDialogVisible"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      width="60%"
      center
    >
      <product v-if="centerDialogVisible" ref="product" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </span>
    </el-dialog>
    <div
      class="mt20 d-flex justify-content-center"
      v-if="$route.query.id ? false : true"
    >
      <el-button @click="save(0)" type="primary">保存到草稿箱</el-button>
      <el-button @click="save(1)">发布</el-button>
    </div>
    <div class="mt20 d-flex justify-content-center" v-else>
      <el-button type="primary" @click="save(draftType)">保存编辑</el-button>
    </div>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import product from "@/pages/marketingActivities/spike/components/product";
import { getDataTimeSec } from "@/utils";
import { insertGoodsAssemble, updateGoodsAssembleInfo } from "@/api/joinGroup";


export default {
  data() {
    return {
      tableData: [],
      specList: [],
      setSpec: [],
      ruleForm: {
        pickerValue: "",
        createLimit: "",
        peopleNum: "",
        maxPurchase: "",
        termOfValidity: 24,
        joinLimit: "",
      },
       pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      rules: {
        pickerValue: [{ required: true, message: "请选择活动时长" }],
        peopleNum: [{ required: true, message: "请输入成团人数" }],
        termOfValidity: [{ required: true, message: "请输入拼团有效期" }],
      },
      centerDialogVisible: false,
      draftType: "",
      isDrafts:''
    };
  },
  computed: {
    setGetDataTimeSec() {
      return (val) => getDataTimeSec(val);
    },
  },
  components: {
    commonTable,
    product,
  },
  created() {

    this.isDrafts = this.$route.query.drafts== '1' ? false : true
    const { id } = this.$route.query;
    if (id) {
      this.updateGoodsAssembleInfo(id);
    }
  },
  methods: {
    determine() {
      const { multipleSelection } = this.$refs.product;
      const arr = [];
      this.setSpec = [];
      this.tableData = [multipleSelection];
      for (let i = 0; i < multipleSelection.goodSpecModelList.length; i++) {
        let type = multipleSelection.goodSpecModelList[i];
        for (let j = 0; j < type.goodSpecModelList.length; j++) {
          let spec = type.goodSpecModelList[j];
          spec.goodsName = multipleSelection.goodsName;
          spec.typeName = type.specName;
          spec.stockNum = multipleSelection.stockNum;
          this.setSpec.push({
            assemblePrice: "",
            assembleNum: "",
            stockNum: spec.stockNum,
            price: spec.price,
            specId: spec.specId,
          });
          arr.push(spec);
        }
      }
      this.specList = arr;
      this.centerDialogVisible = false;
    },
    /**@method  发布*/
    save(type) {
      if (this.tableData.length == 0) {
        this.$message({
          message: "请先选择商品",
          type: "warning",
        });
        return;
      }
      const checkPrice = this.setSpec.find((item) => item.assemblePrice <= 0);
      if (checkPrice) {
        this.$message({
          message: "商品拼团金额不能小于0！",
          type: "warning",
        });
        return;
      }
      console.log(this.setSpec);
      for (let i = 0; i < this.setSpec.length; i++) {
        if (!this.setSpec[i].assemblePrice || !this.setSpec[i].assembleNum) {
          this.$message({
            message: "商品规格拼团金额和拼团库存不能为空或者零！",
            type: "warning",
          });
          return;
        }
        if (this.setSpec[i].assemblePrice * 100 > this.setSpec[i].price) {
          this.$message({
            message: "商品拼团金额不能大于商品价格！",
            type: "warning",
          });
          return;
        }
        if (this.setSpec[i].assembleNum > this.setSpec[i].stockNum) {
          this.$message({
            message: "商品拼团数量不能大于商品总库存！",
            type: "warning",
          });
          return;
        }
      }
      let temp = this.setSpec.map((item) => {
        return {
          specId: item.specId,
          assemblePrice: item.assemblePrice * 100,
          assembleNum: item.assembleNum,
        };
      });
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          
          try {
            let data = {
              assembleId: this.tableData[0].assembleId,
              assembleType: type,
              goodSpecRequestList: temp,
              goodsId: this.tableData[0].goodsId,
              startTimes: `${this.ruleForm.pickerValue[0]} 00:00:00`,
              endTimes: `${this.ruleForm.pickerValue[1]} 23:59:59`,
              updateType: this.$route.query?.drafts == "1" ? 0 : 1,
              peopleNum: this.ruleForm.peopleNum,
              openRegiment: this.ruleForm.createLimit,
              joinRegiment: this.ruleForm.joinLimit,
              maxPurchase: this.ruleForm.maxPurchase,
            };
            console.log(data)
           await insertGoodsAssemble(data);
            this.$message({
              message: type == 1 ? "发布成功" : "保存成功",
              type: "success",
            });
            setTimeout(() => {
              this.$router.go(-1);
            }, 1000);
          } catch (error) {
            this.$message.error(type == 1 ? error.message || "发布失败" : error.message ||"保存失败");
            console.log();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**@method 获取编辑内容 */
    async updateGoodsAssembleInfo(id) {
      const result = await updateGoodsAssembleInfo(
        { assembleId: id },
        { showLoading: true }
      );
      const {
        startTime,
        endTime,
        peopleNum,
        openRegiment,
        maxPurchase,
        joinRegiment,
        assembleType,
        goodSpecModelList,
        goodsName,
        stockNum
      } = result.data;
      this.ruleForm.pickerValue = [
        this.setGetDataTimeSec(startTime),
        this.setGetDataTimeSec(endTime),
      ];
      this.ruleForm.peopleNum = peopleNum;
      this.ruleForm.termOfValidity = result.data.termOfValidity || 24;
      this.ruleForm.createLimit = openRegiment;
      this.ruleForm.maxPurchase = maxPurchase;
      this.ruleForm.joinLimit = joinRegiment;
      this.tableData = [result.data];
      this.draftType = assembleType;
      const arr = [];
      this.setSpec = [];
      for (let i = 0; i < goodSpecModelList.length; i++) {
        let type = goodSpecModelList[i];
        for (let j = 0; j < type.goodSpecModelList.length; j++) {
          let spec = type.goodSpecModelList[j];
          spec.goodsName = goodsName;
          spec.typeName = type.specName;
          spec.stockNum = stockNum;
          this.setSpec.push({
            assemblePrice: spec.assemblePrice / 100,
            assembleNum: spec.assembleNum,
            stockNum: spec.stockNum,
            price: spec.price,
            specId: spec.specId,
          });
          arr.push(spec);
        }
      }
      this.specList = arr;
      console.log(result);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
