import httpUtil from "@/utils/httpUtil";

/**秒杀商品列表 */
export const selectKillGoodsList = async params => httpUtil.post("/api/crmPc/secKill/selectKillGoodsList", params);

/**选择商品 */
export const getGoodsInfoPC = async params => httpUtil.post("/api/crmPc/companyGoods/getGoodsInfoPC", params);

/**发布秒杀 */
export const addKillGoodsInfo = async params => httpUtil.post("/api/crmPc/secKill/addKillGoodsInfo", params);

/** 删除秒杀数据*/
export const delKillGoodsInfo = async params => httpUtil.post("/api/crmPc/secKill/delKillGoodsInfo", params);

/**获取编辑信息 */
export const updateKillGoodsInfo = async (params,type) => httpUtil.post("/api/crmPc/secKill/updateKillGoodsInfo", params,type);

/**发布 */
export const releaseKillGoods = async params => httpUtil.post("/api/crmPc/secKill/releaseKillGoods", params);

/**根据商品类型查询列表 */
export const getGoodsInfoPCShop = async (params,type) => httpUtil.post("/api/crmPc/companyGoods/getGoodsInfoPCShop", params,type);