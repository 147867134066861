import httpUtil from "@/utils/httpUtil";

/**拼团商品列表 */
export const selectGoodsAssembleDraftsPage = async params => httpUtil.post("/api/crmPc/assemble/selectGoodsAssembleDraftsPage", params);


/**发布平拼团商品 */

export const insertGoodsAssemble = async params => httpUtil.post("/api/crmPc/assemble/insertGoodsAssemble", params);

/**拼团编辑获取 */

export const updateGoodsAssembleInfo = async (params,type) => httpUtil.post("/api/crmPc/assemble/updateGoodsAssembleInfo", params,type);

/**删除拼团商品 */
export const delGoodsAssembleInfo = async params => httpUtil.post("/api/crmPc/assemble/delGoodsAssembleInfo", params);

/**发布拼团 */
export const releaseGoodsAssemble = async params => httpUtil.post("/api/crmPc/assemble/releaseGoodsAssemble", params);

/**参团列表 */
export const selectGoodsAssemblePage = async params => httpUtil.post("/api/crmPc/assemble/selectGoodsAssemblePage", params);

/**参团详情 */
export const getGoodsAssembleInfo = async (params,type) => httpUtil.post("/api/crmPc/assemble/getGoodsAssembleInfo", params,type);

/**根据商品类型查询列表 */
export const getGoodsInfoPCShop = async (params,type) => httpUtil.post("/api/crmPc/companyGoods/getGoodsInfoPCShop", params,type);



